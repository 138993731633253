<template>
  <div class="box card-base m-5 p-5 clickable session-time"
    @click="$emit('switchSession', index)"
    :class="{
        'bg-accent': isChecked,
        'locked-panel': isLocked,
        'locked-warning-panel': isBlock
      }">
      <div class="flex column">
          <h5 class="box p-5 center"
            :class="{ 'o-050': !isChecked}">{{item.name}}</h5>
          <h4 class="box center">{{item.time}}</h4>
          <div class="box pv-5 flex justify-space-between">
            <div class="box m-0 fs-13" v-if="isChecked">{{item.left}} / {{item.capacity}}</div>
            <div class="box m-0 fs-13 success-text" v-else-if="item.left > 30">{{item.left}} / {{item.capacity}}</div>
            <div class="box m-0 fs-13 warning-text" v-else-if="item.left > 10">{{item.left}} / {{item.capacity}}</div>
            <div class="box m-0 fs-13 danger-text" v-else>{{item.left}} / {{item.capacity}}</div>
            <div class="box m-0 fs-13 center right" :class="{ 'o-050': !isChecked}">{{price}} AED</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'SessionPanel',

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isChecked: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    isLocked: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    isBlock: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    price: {
      type: Number,
      default: function () {
        return 0
      }
    },
    index: {
      type: Number,
      default: function () {
        return 0
      }
    }
  }
}
</script>
