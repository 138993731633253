<template>
  <div class="box flex gaps center rigth">
    <div class="box left center">
        <h4 class="mt-10 mb-5 o-050">BACK TO BACK</h4>
    </div>
    <div class="box center">
      <el-switch
        v-model="value"
        :disabled="isDisable"
        @change="$emit('switchBTB', $event)">
      </el-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BTBSwitch',

  props: {
    isBackToBack: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    isDisable: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },

  computed: {
    value: {
      get () {
        return this.isBackToBack
      },
      set (newItem) {
        return newItem
      }
    }
  }
}
</script>
