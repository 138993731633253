<template>
  <div class="box flex" v-if="price > 0">
    <div class="box grow flex column card-base clickable"
      @click="$emit('switchType', item._id)"
      :class="{ 'bg-accent': isChecked }">
        <h4 class="box grow ph-5 m-5">{{item.name}}</h4>
        <h5 class="box right bottom pb-5 pr-5 m-5"
          :class="{ 'o-050': !isChecked }">{{price}} AED</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypePanel',

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isChecked: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    price: {
      type: Number,
      default: function () {
        return 0
      }
    },
    index: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },

  data () {
    return {}
  }
}
</script>
