<template>
    <div class="box flex gaps">
      <div class="box grow flex gaps">
        <div class="box left center" style="min-width: 100px;">
            <h4 class="mt-10 mb-5 o-050">PERSONS</h4>
        </div>
        <div class="box flex gaps">
          <div class="box">
            <el-select
              :popper-append-to-body="false"
              style="min-width: 180px;"
              v-model="value"
              multiple
              collapse-tags
              placeholder="Select Person"
              @change="$emit('selectCustomer',$event)">
              <el-option
                v-for="item in basketWaiwers"
                :key="item.value + '-customer'"
                :label="item.label"
                :disabled="isDisabled(item.value)"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="box ph-20 flex card-base bg-primary-lighter">
          <div class="box grow" @click="$onCommandParams('saleAddons')">
            <div class="flex center clickable pv-16">
                <h4 class="m-0">Addons</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SessionPanel',

  props: {
    basketWaiwers: {
      type: Array,
      default: function () {
        return []
      }
    },
    clientIds: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    },
    basketId: {
      type: Number,
      default: function () {
        return -1
      }
    }
  },

  computed: {
    value: {
      get () {
        return this.clientIds.map((x) => x)
      },
      set (newItem) {
        return newItem
      }
    }
  },

  methods: {
    isDisabled (id) {
      return this.basket
        .filter(b => ['Tickets', 'Books'].includes(b.category) && b.basketId !== this.basketId)
        .some(b => b.clientIds.includes(id))
    }
  }
}
</script>
