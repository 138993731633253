<template>
  <div class="box flex gaps">
    <div class="box left center" style="min-width: 100px;">
        <h4 class="mt-10 mb-5 o-050">NUMBER</h4>
    </div>
    <div class="box">
        <el-input-number
          v-model="value"
          :min="1"
          @change="$emit('inputPersons', $event)"></el-input-number>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonInput',

  props: {
    times: {
      type: Number,
      default: function () {
        return 1
      }
    }
  },

  computed: {
    value: {
      get () {
        return this.times
      },
      set (newItem) {
        return newItem
      }
    }
  }
}
</script>
