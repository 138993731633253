<template>
  <div>
      <div class="flex justify-space-between">
          <div class="box self-flex-end"><h2 class="mv-0">TICKETS</h2></div>
          <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
      </div>
      <el-divider class="mv-10 o-020"></el-divider>

      <vue-scroll class="scroll-area" :settings="settings" :style="{height: scrollHeight + 'px'}">

        <div class="flex gaps mt-10">
          <app-type-panel
            @switchType="switchType"
            :item="type"
            :isChecked="currentBusketItem.typeId === type._id"
            :price="typesPrice[index]"
            v-for="(type, index) in types" :key="index + '-type'"></app-type-panel>
        </div>

        <el-divider class="mt-10 mb-5 o-020"></el-divider>

        <div class="flex wrap">
            <app-session-panel
              @switchSession="switchSession"
              :isChecked="[currentBusketItem.sessionIdx, currentBusketItem.sessionIdx2].includes(index)"
              :isLocked="currentSessionIndex > index"
              :isBlock="isBlock(s)"
              :item="s"
              :index="index"
              :price="getPrice(index, currentBusketItem.typeIdx)"
              v-for="(s, index) in todaySessions" :key="index + '-session'"></app-session-panel>
        </div>

        <el-divider class="mt-5 mb-10 o-020"></el-divider>

        <div class="flex column">
          <app-customer-select
            @selectCustomer="selectCustomer"
            :basket="basket"
            :basketId="currentBusketItem.basketId"
            :clientIds="currentBusketItem.clientIds"
            :basketWaiwers="basketWaiwers"></app-customer-select>

          <div class="box flex gaps mt-10 mb-5" >
            <app-person-input
              @inputPersons="inputPersons"
              :times="currentBusketItem.times"></app-person-input>
            <app-btb-switch
              @switchBTB="switchBTB"
              :isDisable="currentBusketItem.isDoubleSession || this.currentBusketItem.sessionIdx === 0"
              :isBackToBack="currentBusketItem.isBackToBack"></app-btb-switch>
          </div>

          <div>
            <app-discount-panel
              @selectDiscount="selectDiscount"
              :selectType="currentBusketItem.discountType"
              :selectMemo="currentBusketItem.discountMemo"
              :customerId="currentBusketItem.clientIds[0]"
              v-if="currentBusketItem.clientIds.length === 1 &&
                !currentBusketItem.isDoubleSession"></app-discount-panel>
          </div>

        </div>
      </vue-scroll>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppTypePanel from './components/TicketTypePanel'
import AppSessionPanel from './components/TicketSessionPanel'
import AppCustomerSelect from './components/TicketCustomerSelect'
import AppPersonInput from './components/TicketPersonInput'
import AppBtbSwitch from './components/TicketBtbSwitch'
import AppDiscountPanel from './components/TicketDiscountPanel'

export default {
  name: 'Tickets',

  components: {
    AppTypePanel,
    AppSessionPanel,
    AppCustomerSelect,
    AppPersonInput,
    AppBtbSwitch,
    AppDiscountPanel
  },

  props: {
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      settings: {
        maxScrollbarLength: 60
      }
    }
  },

  computed: {
    ...mapState({
      types: state => state.type.types,
      double: state => state.double.double.double,
      innerHeight: state => state.nav.innerHeight
    }),
    ...mapGetters([
      'todaySessions',
      'basketWaiwers',
      'currentSessionIndex',
      'getBasketRowById',
      'groupsOneClick'
    ]),
    currentBusketItem () {
      return this.getBasketRowById[this.$route.params.id] ||
        {
          typeId: '',
          typeIdx: 0,
          sessionIdx: this.currentSessionIndex,
          clientIds: []
        }
    },
    typesPrice () {
      return this.todaySessions[this.currentBusketItem.sessionIdx].types.map(t => t.price)
    },
    scrollHeight () {
      return innerHeight - 160
    }
  },
  methods: {
    ...mapActions(['editBasketItem']),
    isBlock (item) {
      return item.hasOwnProperty('block') &&
        item.block.hasOwnProperty('isBlock') &&
        item.block.isBlock
    },
    getPricesArray (idx) {
      return this.todaySessions[idx].types.map(e => e.price)
    },
    getPrice (idx, typeIdx) {
      let pricesArray = this.getPricesArray(idx)
      return pricesArray && pricesArray.length > typeIdx ? pricesArray[typeIdx] : 0
    },
    getBTBrate (sessionIdx, typeIdx) {
      let pa1 = this.getPricesArray(sessionIdx)
      let r1 = pa1[typeIdx]
      if (sessionIdx > 0) {
        let pa2 = this.getPricesArray(sessionIdx - 1)
        let r2 = pa2[typeIdx]
        return Math.ceil((((r1 + r2) * (100 - this.double) / 100) - r2) * 100) / 100
      } else {
        return r1
      }
    },
    switchType (typeId) {
      let typeIdx = this.types.findIndex(t => t._id === typeId)
      let type = this.types[typeIdx]
      let rate

      if (this.currentBusketItem.isDoubleSession) {
        let psa1 = this.getPricesArray(this.currentBusketItem.sessionIdx)
        let psa2 = this.getPricesArray(this.currentBusketItem.sessionIdx2)
        rate = Math.ceil(((psa1[typeIdx] + psa2[typeIdx]) * (100 - this.double) / 100) * 100) / 100
      } else {
        rate = this.currentBusketItem.isBackToBack
          ? this.getBTBrate(this.currentBusketItem.sessionIdx, typeIdx)
          : this.typesPrice[typeIdx]
      }

      this.editBasketItem({
        basketId: this.currentBusketItem.basketId,
        content: {
          typeName: type.name,
          typeId: type._id,
          typeIdx: typeIdx,
          rate
        }
      })
    },
    switchSession (sessionIdx) {
      if (this.currentSessionIndex <= sessionIdx && !this.isBlock(this.todaySessions[sessionIdx])) {
        if (this.currentBusketItem.isDoubleSession) {
          if (sessionIdx === this.currentBusketItem.sessionIdx) {
            let session = this.todaySessions[this.currentBusketItem.sessionIdx2]
            let pricesArray = this.getPricesArray(this.currentBusketItem.sessionIdx2)
            let rate = this.currentBusketItem.isBackToBack
              ? this.getBTBrate(this.currentBusketItem.sessionIdx2, this.currentBusketItem.typeIdx)
              : pricesArray[this.currentBusketItem.typeIdx]
            let isBackToBack = this.currentBusketItem.sessionIdx2 === 0
              ? false : this.currentBusketItem.isBackToBack

            this.editBasketItem({
              basketId: this.currentBusketItem.basketId,
              content: {
                sessionName: session.name,
                sessionTime: session.time,
                sessionIdx: this.currentBusketItem.sessionIdx2,
                sessionIdx2: -1,
                isDoubleSession: false,
                isBackToBack,
                rate
              }
            })
          } else if (sessionIdx === this.currentBusketItem.sessionIdx2) {
            let session = this.todaySessions[this.currentBusketItem.sessionIdx]
            let pricesArray = this.getPricesArray(this.currentBusketItem.sessionIdx)
            let rate = this.currentBusketItem.isBackToBack
              ? this.getBTBrate(this.currentBusketItem.sessionIdx, this.currentBusketItem.typeIdx)
              : pricesArray[this.currentBusketItem.typeIdx]
            let isBackToBack = this.currentBusketItem.sessionIdx === 0
              ? false : this.currentBusketItem.isBackToBack

            this.editBasketItem({
              basketId: this.currentBusketItem.basketId,
              content: {
                sessionName: session.name,
                sessionTime: session.time,
                sessionIdx2: -1,
                isDoubleSession: false,
                isBackToBack,
                rate
              }
            })
          } else {
            let session = this.todaySessions[sessionIdx]
            let pricesArray = this.getPricesArray(sessionIdx)
            let rate = this.currentBusketItem.isBackToBack
              ? this.getBTBrate(sessionIdx, this.currentBusketItem.typeIdx)
              : pricesArray[this.currentBusketItem.typeIdx]
            let isBackToBack = sessionIdx === 0
              ? false : this.currentBusketItem.isBackToBack

            this.editBasketItem({
              basketId: this.currentBusketItem.basketId,
              content: {
                sessionName: session.name,
                sessionTime: session.time,
                sessionIdx: sessionIdx,
                sessionIdx2: -1,
                isDoubleSession: false,
                isBackToBack,
                rate
              }
            })
          }
        } else {
          if (sessionIdx + 1 === this.currentBusketItem.sessionIdx || sessionIdx - 1 === this.currentBusketItem.sessionIdx) {
            let idx1 = sessionIdx > this.currentBusketItem.sessionIdx ? this.currentBusketItem.sessionIdx : sessionIdx
            let idx2 = sessionIdx > this.currentBusketItem.sessionIdx ? sessionIdx : this.currentBusketItem.sessionIdx
            let psa1 = this.getPricesArray(idx1)
            let psa2 = this.getPricesArray(idx2)
            let rate = Math.ceil(((psa1[this.currentBusketItem.typeIdx] + psa2[this.currentBusketItem.typeIdx]) * (100 - this.double) / 100) * 100) / 100
            let start = this.todaySessions[idx1].time.split(' - ')[0]
            let end = this.todaySessions[idx2].time.split(' - ')[1]

            this.editBasketItem({
              basketId: this.currentBusketItem.basketId,
              content: {
                sessionName: 'Double Session',
                sessionTime: [start, end].join(' - '),
                sessionIdx: idx1,
                sessionIdx2: idx2,
                isDoubleSession: true,
                isBackToBack: false,
                discountType: '',
                discountPercent: 0,
                discountMemo: '',
                rate
              }
            })
          } else if (sessionIdx !== this.currentBusketItem.sessionIdx) {
            let session = this.todaySessions[sessionIdx]
            let pricesArray = this.getPricesArray(sessionIdx)
            let rate = this.currentBusketItem.isBackToBack
              ? this.getBTBrate(sessionIdx, this.currentBusketItem.typeIdx)
              : pricesArray[this.currentBusketItem.typeIdx]
            let isBackToBack = sessionIdx === 0
              ? false : this.currentBusketItem.isBackToBack

            this.editBasketItem({
              basketId: this.currentBusketItem.basketId,
              content: {
                sessionName: session.name,
                sessionTime: session.time,
                sessionIdx: sessionIdx,
                sessionIdx2: -1,
                isDoubleSession: false,
                isBackToBack,
                rate
              }
            })
          }
        }
      }
    },
    selectCustomer (value) {
      let clientNames = value.map(v => {
        let w = this.basketWaiwers.find(b => {
          return b.value === v
        })
        return w.hasOwnProperty('label') ? w.label : ''
      })
      let content = {
        times: value.length > 0 ? value.length : 1,
        clientIds: value,
        clientNames
      }
      if (value.length > 1) {
        content = Object.assign(content, {
          discountType: '',
          discountPercent: 0,
          discountMemo: ''
        })
      }
      this.editBasketItem({
        basketId: this.currentBusketItem.basketId,
        content
      })
    },
    inputPersons (value) {
      let content = {
        times: value > 0 ? value : 1
      }
      if (value > 1) {
        let pricesArray = this.getPricesArray(this.currentBusketItem.sessionIdx)
        content = Object.assign(content, {
          discountType: '',
          discountPercent: 0,
          discountMemo: '',
          rate: pricesArray[this.currentBusketItem.typeIdx]
        })
      }
      if (value < this.currentBusketItem.clientIds.length) {
        content = Object.assign(content, {
          clientIds: this.currentBusketItem.clientIds.slice(0, value),
          clientNames: this.currentBusketItem.clientNames.slice(0, value)
        })
      }
      this.editBasketItem({
        basketId: this.currentBusketItem.basketId,
        content
      })
    },
    switchBTB (value) {
      if (!this.currentBusketItem.isDoubleSession && this.currentBusketItem.sessionIdx > 0) {
        if (value) {
          this.editBasketItem({
            basketId: this.currentBusketItem.basketId,
            content: {
              isBackToBack: true,
              discountType: '',
              discountPercent: 0,
              discountMemo: '',
              rate: this.getBTBrate(this.currentBusketItem.sessionIdx, this.currentBusketItem.typeIdx)
            }
          })
        } else {
          let pricesArray = this.getPricesArray(this.currentBusketItem.sessionIdx)
          this.editBasketItem({
            basketId: this.currentBusketItem.basketId,
            content: {
              isBackToBack: false,
              rate: pricesArray[this.currentBusketItem.typeIdx]
            }
          })
        }
      }
    },
    selectDiscount ({ type, per, memo }) {
      let pricesArray = this.getPricesArray(this.currentBusketItem.sessionIdx)
      let content = { isBackToBack: false }
      if (this.currentBusketItem.discountType !== type || this.currentBusketItem.discountMemo !== memo) {
        content = Object.assign(content, {
          discountType: type,
          discountPercent: per,
          discountMemo: memo,
          rate: Math.ceil((pricesArray[this.currentBusketItem.typeIdx] * (100 - per) / 100) * 100) / 100
        })
      } else {
        content = Object.assign(content, {
          discountType: '',
          discountPercent: 0,
          discountMemo: '',
          rate: pricesArray[this.currentBusketItem.typeIdx]
        })
      }
      this.editBasketItem({
        basketId: this.currentBusketItem.basketId,
        content
      })
    }
  }
}
</script>
