<template>
  <div>
    <div class="box flex">
      <div class="box flex m-5"
        v-if="customer.hasOwnProperty('subscription') && customer.subscription > 0">
        <div class="box grow flex column card-base clickable"
          :class="{'bg-accent' : selectType === 'Subscription'}"
          @click="$emit('selectDiscount', {
            type: 'Subscription',
            per: 100,
            memo: ''
          })">
            <h4 class="box pl-10 m-5">Prepaid Session</h4>
            <h5 class="box right bottom pb-5 pr-5 m-5"
              :class="{ 'o-050': selectType !== 'Subscription' }">
              Total {{customer.subscription}}
            </h5>
        </div>
      </div>
      <div class="box flex m-5"
        v-if="customer.hasOwnProperty('lesson') && customer.lesson > 0">
        <div class="box grow flex column card-base clickable"
          :class="{'bg-accent' : selectType === 'Lesson'}"
          @click="$emit('selectDiscount', {
            type: 'Lesson',
            per: 100,
            memo: ''
          })">
            <h4 class="box pl-10 m-5">Prepaid Lesson</h4>
            <h5 class="box right bottom pb-5 pr-5 m-5"
              :class="{ 'o-050': selectType !== 'Lesson' }">
              Total {{customer.lesson}}
            </h5>
        </div>
      </div>
    </div>

    <el-divider class="mv-5 o-020"></el-divider>

    <div class="box flex wrap">
      <div class="box flex m-5"
        v-for="(g, index) in groupsOneClick" :key="index + '-group'">
          <div class="box grow flex column card-base clickable"
            :class="{'bg-accent' : selectType === 'Group' && selectMemo === g.name}"
            @click="$emit('selectDiscount', {
              type: 'Group',
              per: g.discount,
              memo: g.name
            })">
              <h4 class="box ph-10 m-5 ellipsis">{{g.name}}</h4>
              <h5 class="box right bottom pb-5 pr-5 m-5"
                :class="{ 'o-050': selectType !== 'Group' || selectMemo !== g.name}">
                Discount {{g.discount}}%
              </h5>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DiscountPanel',

  props: {
    customerId: {
      type: String,
      default: function () {
        return ''
      }
    },
    selectType: {
      type: String,
      default: function () {
        return ''
      }
    },
    selectMemo: {
      type: String,
      default: function () {
        return ''
      }
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapGetters(['getMemberById', 'groupsOneClick']),
    customer () {
      return this.getMemberById[this.customerId]
    }
  }
}
</script>
